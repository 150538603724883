<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <!-- 新建考试 -->
        <div class="crumbs">
          <router-link class="crumbs_item" tag="a" to="/admin/Examination/ExamSeting">新建考试 / </router-link>
          <span class="crumbs_item crumbs_last">考试名单</span>
        </div>
      </template>
      <template #input>
        <!-- <span class="tag">标签名称：</span> -->
        <a-input placeholder="请输入关键字" v-model="name" />
        <a-button type="primary" class="btn" @click="getExamPeopleList()">搜索</a-button>
      </template>
    </HeaderBox>
    
    <a-table
        class="table-template"
        :columns="columns"
        :data-source="tableData"
        :rowKey="item=>item.userPaperId"
        @change="onPage"
        :pagination="{
          hideOnSinglePage:true,
          total:total,
          current:pageNumber,
          defaultPageSize:pageSize,
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template
          slot="index"
          slot-scope="item, row, index"
        >
          <!-- {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }} -->
          {{index + 1}}
        </template>
      </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    width:"80px",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "用户名",
    align:'center',
    dataIndex: "userName"
  },
  {
    title: "姓名",
    align:'center',
    dataIndex: "name"
  },
  {
    title: "手机号",
    align:'center',
    dataIndex: "mobile"
  },
  {
    title: "身份",
    align:'center',
    dataIndex: "userType"
  },
  {
    title: "参考次数",
    align:'center',
    dataIndex: "examNum"
  },
  {
    title: "实付金额",
    align:'center',
    width: "160px",
    dataIndex: "price"
  }
];
import HeaderBox from '@/components/HeaderBox.vue'
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      name:'', // 搜索关键字
      tabIndex: 1,// tab
      columns, // 考试模板
      tableData:[], // 列表数据
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      visible: false, // 弹框状态
      confirmLoading: false, // 按钮状态
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.pageNumber = e.current
      this.pageSize = e.pageSize
      this.getExamPeopleList()
    },
    // 获取考生名单
    getExamPeopleList(){
      this.$ajax({
        url:'/hxclass-management/exam/mock/config/exminee/list',
        method:'get',
        params:{
          configId:this.$route.query.configId,	// 模考设置id
          current:this.pageNumber,	// 当前页
          size:this.pageSize,	// 总条数
          name:this.name
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getExamPeopleList();
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  a {
    color: #333;
  }
  .function{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/ .ant-tabs-bar{
      border-bottom: none;
    }
    .all_boder_btn{
      margin-right: 20px;
    }
    div{
      display: flex;
      .ant-input{
        width: 240px;
        margin-right: 24px;
      }
    }
  }
  .btn{
    margin-right: 24px;
  }
</style>
