var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"crumbs"},[_c('router-link',{staticClass:"crumbs_item",attrs:{"tag":"a","to":"/admin/Examination/ExamSeting"}},[_vm._v("新建考试 / ")]),_c('span',{staticClass:"crumbs_item crumbs_last"},[_vm._v("考试名单")])],1)]},proxy:true},{key:"input",fn:function(){return [_c('a-input',{attrs:{"placeholder":"请输入关键字"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.getExamPeopleList()}}},[_vm._v("搜索")])]},proxy:true}])}),_c('a-table',{staticClass:"table-template",attrs:{"columns":_vm.columns,"data-source":_vm.tableData,"rowKey":function (item){ return item.userPaperId; },"pagination":{
        hideOnSinglePage:true,
        total:_vm.total,
        current:_vm.pageNumber,
        defaultPageSize:_vm.pageSize,
        showSizeChanger: true,
        showTotal: function(total, range){
        return ("共" + total + "条")}}},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, index){return [_vm._v(" "+_vm._s(index + 1)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }